// Horizontal Navigation Pattern
// =============================
// horizontal only with large enough screen


.nav-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  position: relative;

  @include below('nav-update') {
    flex-grow: 1;
  }

  > ul {
    display: inline-flex;
  }

  li {
    display: inline-block;
  }

  .nav-dropdown {
    li {
      display: block;
    }

    a {
      #{$focus} {
        background-color: color('link-hover');
      }
    }

    button {
      text-align: left;
      width: 100%;

      #{$focus} {
        background-color: color('link-hover');
      }
    }
  }

  button,
  a {
    @include font-family('sans');
    display: block;
    padding: size('link-pad');
  }
}

ul {
  header &,
  footer & {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
