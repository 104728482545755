// Navigation Drop-Down Pattern
// ============================

.has-dropdown {
  @include above('page') {
    position: relative;
  }

  &.is-active {
    background-color: color('link');
  }
}

.nav-dropdown {
  @include shadow('link', 'link-hover');
  @include z-index('nav-dropdown');
  display: block;
  max-width: 100vw;
  position: absolute;
  right: 0;
  top: 100%;

  &[aria-expanded='false'] {
    display: none;
  }

  @include above('nav-update') {
    max-width: size('nav-update');
  }

  @include above('page') {
    margin-left: negative('nav-update'); // allow drop-downs to expand left
  }
}
