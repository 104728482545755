// sass-lint: disable-all

/**
 * This is a copy of the stylesheet for the GWT Clean theme.
 */

$images: '../algebrasolving/gwt/clean/images';

pre {
  font-family: "courier new", courier;
  font-size: small;
}

select {
  background: white;
}

/**
 * The reference theme can be used to determine when this style sheet has
 * loaded.  Create a hidden div element with absolute position, assign the style
 * name below, and attach it to the DOM.  Use a timer to detect when the
 * element's height and width are set to 5px.
 */
.gwt-Reference-clean {
  height: 5px;
  width: 5px;
  zoom: 1;
}

.gwt-Button[disabled] {
  cursor: default;
  color: #888;
}

.gwt-Button[disabled]:hover {
  border: 1px outset #ccc;
}

.gwt-CheckBox-disabled {
  color: #888;
}

.gwt-DecoratorPanel .topCenter {
  border-top: 1px solid #bbb;
  line-height: 0;
}

.gwt-DecoratorPanel .bottomCenter {
  border-bottom: 1px solid #bbb;
  line-height: 0;
}

.gwt-DecoratorPanel .topCenterInner,
.gwt-DecoratorPanel .bottomCenterInner {
  height: 1px;
  line-height: 0;
  font-size: 1px;
}

.gwt-DecoratorPanel .middleLeft {
  border-left: 1px solid #bbb;
}

.gwt-DecoratorPanel .middleRight {
  border-right: 1px solid #bbb;
}

.gwt-DecoratorPanel .middleLeftInner,
.gwt-DecoratorPanel .middleRightInner {
  width: 1px;
  line-height: 1px;
}

.gwt-DecoratorPanel .topLeftInner,
.gwt-DecoratorPanel .topRightInner,
.gwt-DecoratorPanel .bottomLeftInner,
.gwt-DecoratorPanel .bottomRightInner {
  width: 5px;
  height: 5px;
  zoom: 1;
  font-size: 1px;
  overflow: hidden;
}

.gwt-DecoratorPanel .topLeft {
  line-height: 0px;
  background: url(#{$images}/circles.png) no-repeat 0px -6px;
}

.gwt-DecoratorPanel .topRight {
  line-height: 0px;
  background: url(#{$images}/circles.png) no-repeat -5px -6px;
}

.gwt-DecoratorPanel .bottomLeft {
  line-height: 0px;
  background: url(#{$images}/circles.png) no-repeat 0px -11px;
}

.gwt-DecoratorPanel .bottomRight {
  line-height: 0px;
  background: url(#{$images}/circles.png) no-repeat -5px -11px;
}

.gwt-DisclosurePanel .header,
.gwt-DisclosurePanel .header a,
.gwt-DisclosurePanel .header td {
  text-decoration: none;  /* Remove underline from header */
  color: black;
  cursor: pointer;
  cursor: hand;
}

.gwt-DisclosurePanel .content {
  border-left: 3px solid #e7e7e7;
  padding: 4px 0 4px 8px;
  margin-left: 6px;
}

.gwt-Frame {
  border-top: 2px solid #666;
  border-left: 2px solid #666;
  border-right: 2px solid #bbb;
  border-bottom: 2px solid #bbb;
}

.gwt-HorizontalSplitPanel .hsplitter {
  cursor: move;
  border: 0;
  background: #e7e7e7;
  line-height: 0;
}

// .gwt-VerticalSplitPanel {
// }

.gwt-VerticalSplitPanel .vsplitter {
  cursor: move;
  border: 0;
  background: #e7e7e7;
  line-height: 0;
}

.gwt-HTML {
  padding: 0 0;
}

.gwt-Hyperlink {
  cursor: pointer;
}

.gwt-MenuBar {
  cursor: default;
}

.gwt-MenuBar .gwt-MenuItem {
  cursor: default;
  font-family: Arial Unicode MS, Arial, sans-serif;
}

.gwt-MenuBar .gwt-MenuItem-selected {
  background: #E3E8F3;
}

.gwt-MenuBar-horizontal {
  background: #e3e8f3 url(#{$images}/hborder.png) repeat-x 0px -2003px;
  border: 1px solid #e0e0e0;
}

.gwt-MenuBar-horizontal .gwt-MenuItem {
  padding: 5px 10px;
  vertical-align: bottom;
  color: #000;
  font-weight: bold;
}

.gwt-MenuBar-horizontal .gwt-MenuItemSeparator {
  width: 1px;
  padding: 0;
  margin: 0;
  border: 0;
  border-left: 1px solid #ccc;
  background: white;
}

.gwt-MenuBar-horizontal .gwt-MenuItemSeparator .menuSeparatorInner {
  width: 1px;
  height: 1px;
  background: white;
}

.gwt-MenuBar-vertical {
  margin-top: 0;
  margin-left: 0;
  background: white;
}

.gwt-MenuBar-vertical table {
  border-collapse: collapse;
}

.gwt-MenuBar-vertical .gwt-MenuItem {
  padding: 2px 40px 2px 1px;
}

.gwt-MenuBar-vertical .gwt-MenuItemSeparator {
  padding: 2px 0;
}

.gwt-MenuBar-vertical .gwt-MenuItemSeparator .menuSeparatorInner {
  height: 1px;
  padding: 0;
  border: 0;
  border-top: 1px solid #ccc;
  overflow: hidden;
}

.gwt-MenuBar-vertical .subMenuIcon {
  padding-right: 4px;
}

.gwt-MenuBar-vertical .subMenuIcon-selected {
  background: #E3E8F3;
}

.gwt-MenuBarPopup .menuPopupTopCenter {
  background: url(#{$images}/hborder.png) 0px -12px repeat-x;
}

.gwt-MenuBarPopup .menuPopupBottomCenter {
  background: url(#{$images}/hborder.png) 0px -13px repeat-x;
}

.gwt-MenuBarPopup .menuPopupMiddleLeft {
  background: url(#{$images}/vborder.png) -12px 0px repeat-y;
}

.gwt-MenuBarPopup .menuPopupMiddleRight {
  background: url(#{$images}/vborder.png) -13px 0px repeat-y;
}

.gwt-MenuBarPopup .menuPopupTopLeftInner {
  width: 5px;
  height: 5px;
  zoom: 1;
}

.gwt-MenuBarPopup .menuPopupTopRightInner {
  width: 8px;
  height: 5px;
  zoom: 1;
}

.gwt-MenuBarPopup .menuPopupBottomLeftInner {
  width: 5px;
  height: 8px;
  zoom: 1;
}

.gwt-MenuBarPopup .menuPopupBottomRightInner {
  width: 8px;
  height: 8px;
  zoom: 1;
}

.gwt-MenuBarPopup .menuPopupTopLeft {
  background: url(#{$images}/corner.png) no-repeat 0px -36px;
}

.gwt-MenuBarPopup .menuPopupTopRight {
  background: url(#{$images}/corner.png) no-repeat -5px -36px;
}

.gwt-MenuBarPopup .menuPopupBottomLeft {
  background: url(#{$images}/corner.png) no-repeat 0px -41px;
}

.gwt-MenuBarPopup .menuPopupBottomRight {
  background: url(#{$images}/corner.png) no-repeat -5px -41px;
}

.gwt-PasswordTextBox {
  padding: 5px 4px;
  border: 1px solid #ccc;
  border-top: 1px solid #999;
  font-size: 100%;
}

.gwt-PasswordTextBox-readonly {
  color: #888;
}

.gwt-PopupPanel {
  border: 3px solid #e7e7e7;
  padding: 3px;
  background: white;
}

.gwt-DecoratedPopupPanel .popupMiddleCenter {
  padding: 3px;
  background: #f1f1f1;
}

.gwt-DecoratedPopupPanel .popupTopCenter {
  background: url(#{$images}/hborder.png) 0px -2937px repeat-x;
}

.gwt-DecoratedPopupPanel .popupBottomCenter {
  background:  url(#{$images}/hborder.png) repeat-x 0px -2938px;
}

.gwt-DecoratedPopupPanel .popupMiddleLeft {
  background: url(#{$images}/vborder.png) -21px 0px repeat-y;
}

.gwt-DecoratedPopupPanel .popupMiddleRight {
  background: url(#{$images}/vborder.png) repeat-y -24px 0px;
}

.gwt-DecoratedPopupPanel .popupTopLeftInner {
  width: 6px;
  height: 5px;
  zoom: 1;
}

.gwt-DecoratedPopupPanel .popupTopRightInner {
  width: 6px;
  height: 5px;
  zoom: 1;
}

.gwt-DecoratedPopupPanel .popupBottomLeftInner {
  width: 6px;
  height: 6px;
  zoom: 1;
}

.gwt-DecoratedPopupPanel .popupBottomRightInner {
  width: 6px;
  height: 6px;
  zoom: 1;
}

.gwt-DecoratedPopupPanel .popupTopLeft {
  background: url(#{$images}/circles.png) no-repeat 0px -16px;
}

.gwt-DecoratedPopupPanel .popupTopRight {
  background: url(#{$images}/circles.png) no-repeat -6px -16px;
}

.gwt-DecoratedPopupPanel .popupBottomLeft {
  background: url(#{$images}/circles.png) no-repeat 0px -21px;
}

.gwt-DecoratedPopupPanel .popupBottomRight {
  background: url(#{$images}/circles.png) no-repeat -6px -21px;
}

.gwt-PopupPanelGlass {
  background-color: #000;
  opacity: 0.3;
  filter: alpha(opacity=30);
}

.gwt-PushButton-up,
.gwt-PushButton-up-hovering,
.gwt-PushButton-up-disabled,
.gwt-PushButton-down,
.gwt-PushButton-down-hovering,
.gwt-PushButton-down-disabled {
  margin: 0;
  text-decoration: none;
  background: url("#{$images}/hborder.png") repeat-x 0px -27px;
  border-radius: 2px;
  -moz-border-radius: 2px;
}

.gwt-PushButton-up,
.gwt-PushButton-up-hovering,
.gwt-PushButton-up-disabled {
  padding: 3px 5px 3px 5px;
}

.gwt-PushButton-up {
  border: 1px solid #bbb;
  border-bottom: 1px solid #a0a0a0;
  cursor: pointer;
  cursor: hand;
}

.gwt-PushButton-up-hovering {
  border: 1px solid;
  border-color: #939393;
  cursor: pointer;
  cursor: hand;
}

.gwt-PushButton-up-disabled {
  border: 1px solid #bbb;
  cursor: default;
  opacity: .5;
  filter: alpha(opacity=45);
  zoom: 1;
}

.gwt-PushButton-down,
.gwt-PushButton-down-hovering,
.gwt-PushButton-down-disabled {
  padding: 4px 4px 2px 6px;
  outline: none;
}

.gwt-PushButton-down {
  border: 1px inset #666;
  cursor: pointer;
  cursor: hand;
}

.gwt-PushButton-down-hovering {
  border: 1px solid #939393;
  border-top: 1px solid #333333;
  cursor: pointer;
  cursor: hand;
}

.gwt-PushButton-down-disabled {
  border: 1px outset #ccc;
  cursor: default;
  opacity: 0.5;
  filter: alpha(opacity=45);
  zoom: 1;
}

.gwt-RadioButton-disabled {
  color: #888;
}

.hasRichTextToolbar {
  border: 0;
}

.gwt-RichTextToolbar {
  background: #e3e8f3 url(#{$images}/hborder.png) repeat-x 0px -2003px;
  border-bottom: 1px solid #BBBBBB;
  padding: 3px;
  margin: 0;
}

.gwt-RichTextToolbar .gwt-PushButton-up {
  padding: 0 1px 0 0;
  margin-right: 4px;
  margin-bottom: 4px;
  border-width: 1px;
}

.gwt-RichTextToolbar .gwt-PushButton-up-hovering {
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 0 1px 0 0;
  border-width: 1px;
}

.gwt-RichTextToolbar .gwt-PushButton-down {
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 0 0 0 1px;
  border-width: 1px;
}

.gwt-RichTextToolbar .gwt-PushButton-down-hovering {
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 0 0 0 1px;
  border-width: 1px;
}

.gwt-RichTextToolbar .gwt-ToggleButton-up {
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 0 1px 0 0;
  border: 1px solid #bbb;
  border-bottom: 1px solid #a0a0a0;
}

.gwt-RichTextToolbar .gwt-ToggleButton-up-hovering {
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 0 1px 0 0;
  border-width: 1px;
}

.gwt-RichTextToolbar .gwt-ToggleButton-down {
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 0 0 0 1px;
  border-width: 1px;
}

.gwt-RichTextToolbar .gwt-ToggleButton-down-hovering {
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 0 0 0 1px;
  border-width: 1px;
}

.gwt-StackPanel {
  border-bottom: 1px solid #bbbbbb;
}

.gwt-StackPanel .gwt-StackPanelItem {
  cursor: pointer;
  cursor: hand;
  font-weight: bold;
  font-size: 1.3em;
  padding: 3px;
  border: 1px solid #bbbbbb;
  border-bottom: 0px;
  background: #d3def6 url(#{$images}/hborder.png) repeat-x 0px -989px;
}

.gwt-StackPanel .gwt-StackPanelContent {
  border: 1px solid #bbbbbb;
  border-bottom: 0;
  background: white;
  padding: 2px 2px 10px 5px;
}

.gwt-DecoratedStackPanel {
  border-bottom: 1px solid #bbbbbb;
}

.gwt-DecoratedStackPanel .gwt-StackPanelContent {
  border: 1px solid #bbbbbb;
  border-bottom: 0;
  background: white;
  padding: 2px 2px 10px 5px;
}

.gwt-DecoratedStackPanel .gwt-StackPanelItem {
  cursor: pointer;
  cursor: hand;
}

.gwt-DecoratedStackPanel .stackItemTopLeft,
.gwt-DecoratedStackPanel .stackItemTopRight {
  height: 6px;
  width: 6px;
  zoom: 1;
}

.gwt-DecoratedStackPanel .stackItemTopLeft {
  border-left: 1px solid #bbbbbb;
  background: #d3def6 url(#{$images}/corner.png) no-repeat 0px -49px;
}

.gwt-DecoratedStackPanel .stackItemTopRight {
  border-right: 1px solid #bbbbbb;
  background: #d3def6 url(#{$images}/corner.png) no-repeat -6px -49px;
}

.gwt-DecoratedStackPanel .stackItemTopLeftInner,
.gwt-DecoratedStackPanel .stackItemTopRightInner {
  width: 1px;
  height: 1px;
}

.gwt-DecoratedStackPanel .stackItemTopCenter {
  background: url(#{$images}/hborder.png) 0px -21px repeat-x;
}

.gwt-DecoratedStackPanel .stackItemMiddleLeft {
  background: #d3def6 url(#{$images}/hborder.png) repeat-x 0px -989px;
  border-left: 1px solid #bbbbbb;
}

.gwt-DecoratedStackPanel .stackItemMiddleLeftInner,
.gwt-DecoratedStackPanel .stackItemMiddleRightInner {
  width: 1px;
  height: 1px;
}

.gwt-DecoratedStackPanel .stackItemMiddleRight {
  background: #d3def6 url(#{$images}/hborder.png) repeat-x 0px -989px;
  border-right: 1px solid #bbbbbb;
}

.gwt-DecoratedStackPanel .stackItemMiddleCenter {
  font-weight: bold;
  font-size: 1.3em;
  background: #d3def6 url(#{$images}/hborder.png) repeat-x 0px -989px;
}

.gwt-DecoratedStackPanel .gwt-StackPanelItem-first .stackItemTopRight,
.gwt-DecoratedStackPanel .gwt-StackPanelItem-first .stackItemTopLeft {
  border: 0;
  background-color: white;
}

.gwt-DecoratedStackPanel .gwt-StackPanelItem-below-selected .stackItemTopLeft,
.gwt-DecoratedStackPanel .gwt-StackPanelItem-below-selected .stackItemTopRight {
  background-color: white;
}

.gwt-SuggestBox {
  padding: 5px 4px;
  border: 1px solid #ccc;
  border-top: 1px solid #999;
  font-size: 100%;
  font-family: Arial Unicode MS, Arial, sans-serif;
}

.gwt-SuggestBoxPopup .item {
  padding: 2px 6px;
  color: #000;
  cursor: default;
  font-size: 110%;
}

.gwt-SuggestBoxPopup .item-selected {
  background: #D5E2FF;
}

.gwt-SuggestBoxPopup .suggestPopupContent {
  background: white;
}

.gwt-SuggestBoxPopup .suggestPopupTopCenter {
  border-top: 1px solid #bbb;
}

.gwt-SuggestBoxPopup .suggestPopupBottomCenter {
  border-bottom: 1px solid #bbb;
}

.gwt-SuggestBoxPopup .suggestPopupTopCenterInner,
.gwt-SuggestBoxPopup .suggestPopupBottomCenterInner {
  height: 1px;
  line-height: 1px;
}

.gwt-SuggestBoxPopup .suggestPopupMiddleLeft {
  border-left: 1px solid #bbb;
}

.gwt-SuggestBoxPopup .suggestPopupMiddleRight {
  border-right: 1px solid #bbb;
}

.gwt-SuggestBoxPopup .suggestPopupMiddleLeftInner,
.gwt-SuggestBoxPopup .suggestPopupMiddleRightInner {
  width: 1px;
  line-height: 1px;
}

.gwt-SuggestBoxPopup .suggestPopupTopLeftInner {
  width: 0;
  height: 0;
  zoom: 1;
}

.gwt-SuggestBoxPopup .suggestPopupTopRightInner {
  width: 0;
  height: 0;
  zoom: 1;
}

.gwt-SuggestBoxPopup .suggestPopupBottomLeftInner {
  width: 0;
  height: 0;
  zoom: 1;
}

.gwt-SuggestBoxPopup .suggestPopupBottomRightInner {
  width: 0;
  height: 0;
  zoom: 1;
}

.gwt-SuggestBoxPopup .suggestPopupTopLeft {
  background: url(#{$images}/circles.png) no-repeat 0px -6px;
  width: 5px;
  height: 5px;
}

.gwt-SuggestBoxPopup .suggestPopupTopRight {
  background: url(#{$images}/circles.png) no-repeat -5px -6px;
  width: 5px;
  height: 5px;
}

.gwt-SuggestBoxPopup .suggestPopupBottomLeft {
  background: url(#{$images}/circles.png) no-repeat 0px -11px;
  width: 5px;
  height: 5px;
}

.gwt-SuggestBoxPopup .suggestPopupBottomRight {
  background: url(#{$images}/circles.png) no-repeat -5px -11px;
  width: 5px;
  height: 5px;
}

.gwt-TabBar {
  background: #ccc;
  padding-top: 6px;
}

.gwt-TabBar .gwt-TabBarFirst {
  width: 5px;  /* first tab distance from the left */
}

.gwt-TabBar .gwt-TabBarItem {
  margin-left: 4px;
  padding: 4px 8px 4px 8px;
  cursor: pointer;
  cursor: hand;
  color: white;
  font-weight: normal;
  text-align: center;
  background: #8E8E8E;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.gwt-TabBar .gwt-TabBarItem-selected {
  cursor: default;
  background: white;
  color: #333;
  font-weight: bold;
}

.gwt-TabBar .gwt-TabBarItem-disabled {
  cursor: default;
  color: #999999;
}

.gwt-TabPanelBottom {
  border-color: #ccc;
  border-style: solid;
  border-width: 0 1px 1px;
  overflow: hidden;
  padding: 6px;
}

.gwt-DecoratedTabBar {
  background: #ccc;
  padding-top: 6px;
}

.gwt-DecoratedTabBar .gwt-TabBarFirst {
  width: 5px;  /* first tab distance from the left */
}

.gwt-DecoratedTabBar .gwt-TabBarItem {
  border-collapse: collapse;
  margin-left: 4px;
}

.gwt-DecoratedTabBar .tabTopCenter {
  padding: 0;
  background: #8E8E8E;
}

.gwt-DecoratedTabBar .tabTopLeft,
.gwt-DecoratedTabBar .tabTopRight {
  padding: 0;
  zoom: 1;
}

.gwt-DecoratedTabBar .tabTopLeftInner,
.gwt-DecoratedTabBar .tabTopRightInner {
  width: 3px;
  height: 3px;
}

.gwt-DecoratedTabBar .tabTopLeft {
  background: url(#{$images}/circles.png) no-repeat 0px 0px;
}

.gwt-DecoratedTabBar .tabTopRight {
  background: url(#{$images}/circles.png) no-repeat -3px 0px;
}

.gwt-DecoratedTabBar .tabMiddleLeft,
.gwt-DecoratedTabBar .tabMiddleRight {
  width: 3px;
  padding: 0;
  background: #8E8E8E;
}

.gwt-DecoratedTabBar .tabMiddleLeftInner,
.gwt-DecoratedTabBar .tabMiddleRightInner {
  width: 1px;
  height: 1px;
}

.gwt-DecoratedTabBar .tabMiddleCenter {
  padding: 0 5px 4px 5px;
  cursor: pointer;
  cursor: hand;
  color: #fff;
  font-weight: normal;
  text-align: center;
  background: #8E8E8E;
}

.gwt-DecoratedTabBar .gwt-TabBarItem-selected .tabTopCenter {
  background: #fff;
}

.gwt-DecoratedTabBar .gwt-TabBarItem-selected .tabTopLeft {
  background: url(#{$images}/circles.png) no-repeat -6px 0px;
}

.gwt-DecoratedTabBar .gwt-TabBarItem-selected .tabTopRight {
  background: url(#{$images}/circles.png) no-repeat -9px 0px;
}

.gwt-DecoratedTabBar .gwt-TabBarItem-selected .tabMiddleLeft,
.gwt-DecoratedTabBar .gwt-TabBarItem-selected .tabMiddleRight {
  background: #fff;
}

.gwt-DecoratedTabBar .gwt-TabBarItem-selected .tabMiddleCenter {
  cursor: default;
  background: #fff;
  color: #333;
  font-weight: bold;
}

.gwt-DecoratedTabBar .gwt-TabBarItem-disabled .tabMiddleCenter {
  cursor: default;
  color: #999999;
}

.gwt-TextArea {
  // padding: 4px;
  // border: 1px solid #ccc;
  // border-top: 1px solid #666;
  // font-size: 100%;
  // font-family: Arial Unicode MS, Arial, sans-serif;
}

.gwt-TextArea-readonly {
  color: #888;
}

.gwt-TextBox {
  // padding: 5px 4px;
  // border: 1px solid #ccc;
  // border-top: 1px solid #999;
  // font-size: small;
  // font-family: Arial Unicode MS, Arial, sans-serif;
}

.gwt-TextBox-readonly {
  color: #888;
}

.gwt-ToggleButton-up,
.gwt-ToggleButton-up-hovering,
.gwt-ToggleButton-up-disabled,
.gwt-ToggleButton-down,
.gwt-ToggleButton-down-hovering,
.gwt-ToggleButton-down-disabled {
  margin: 0;
  text-decoration: none;
  background: url("#{$images}/hborder.png") repeat-x 0px -27px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.gwt-ToggleButton-up,
.gwt-ToggleButton-up-hovering,
.gwt-ToggleButton-up-disabled {
  padding: 3px 5px 3px 5px;
}

.gwt-ToggleButton-up {
  border: 1px solid #bbb;
  border-bottom: 1px solid #a0a0a0;
  cursor: pointer;
  cursor: hand;
}

.gwt-ToggleButton-up-hovering {
  border: 1px solid;
  border-color: #939393;
  cursor: pointer;
  cursor: hand;
}

.gwt-ToggleButton-up-disabled {
  border: 1px solid #bbb;
  cursor: default;
  opacity: .5;
  zoom: 1;
  filter: alpha(opacity=45);
}

.gwt-ToggleButton-down,
.gwt-ToggleButton-down-hovering,
.gwt-ToggleButton-down-disabled {
  padding: 4px 4px 2px 6px;
}

.gwt-ToggleButton-down {
  background-position: 0 -513px;
  border: 1px inset #666;
  cursor: pointer;
  cursor: hand;
}

.gwt-ToggleButton-down-hovering {
  background-position: 0 -513px;
  border: 1px inset;
  border-color: #9cf #69e #69e #7af;
  cursor: pointer;
  cursor: hand;
}

.gwt-ToggleButton-down-disabled {
  background-position: 0 -513px;
  border: 1px inset #ccc;
  cursor: default;
  opacity: .5;
  zoom: 1;
  filter: alpha(opacity=45);
}

.gwt-Tree .gwt-TreeItem {
  padding: 1px 0;
  margin: 0;
  white-space: nowrap;
  cursor: hand;
  cursor: pointer;
}

.gwt-Tree .gwt-TreeItem-selected {
  background: #ebeff9;
}

.gwt-TreeItem .gwt-RadioButton input,
.gwt-TreeItem .gwt-CheckBox input {
  margin-left: 0;
}

.gwt-DateBox {
  padding: 5px 4px;
  border: 1px solid #ccc;
  border-top: 1px solid #999;
  font-size: 100%;
}

.gwt-DateBox input {
  width: 8em;
}

.dateBoxFormatError {
  background: #ffcccc;
}

.gwt-DatePicker {
  border: 1px solid #ccc;
  border-top: 1px solid #999;
  cursor: default;
}

.gwt-DatePicker td,
.datePickerMonthSelector td:focus {
  outline: none;
}

.datePickerDays {
  width: 100%;
  background: white;
}

.datePickerDay,
.datePickerWeekdayLabel,
.datePickerWeekendLabel {
  font-size: 85%;
  text-align: center;
  padding: 4px;
  outline: none;
  font-weight: bold;
  color: #333;
  border-right: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
}

.datePickerWeekdayLabel,
.datePickerWeekendLabel {
  background: #fff;
  padding: 0 4px 2px;
  cursor: default;
  color: #666;
  font-size: 70%;
  font-weight: normal;
}

.datePickerDay {
  padding: 4px 7px;
  cursor: hand;
  cursor: pointer;
}

.datePickerDayIsWeekend {
  background: #f7f7f7;
}

.datePickerDayIsFiller {
  color: #999;
  font-weight: normal;
}

.datePickerDayIsValue {
  background: #d7dfe8;
}

.datePickerDayIsDisabled {
  color: #AAAAAA;
  font-style: italic;
}

.datePickerDayIsHighlighted {
  background: #F0E68C;
}

.datePickerDayIsValueAndHighlighted {
  background: #d7dfe8;
}

.datePickerDayIsToday {
  padding: 3px;
  color: #fff;
  background: url(#{$images}/hborder.png) repeat-x 0px -2607px;
}

.datePickerMonthSelector {
  width: 100%;
  padding: 1px 0 5px 0;
  background: #fff;
}

td.datePickerMonth,
td.datePickerYear {
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  font-size: 100%;
  font-weight: bold;
  color: #333;
}

.datePickerPreviousButton,
.datePickerNextButton,
.datePickerPreviousYearButton,
.datePickerNextYearButton {
  font-size: 120%;
  line-height: 1em;
  color: #3a6aad;
  cursor: hand;
  cursor: pointer;
  font-weight: bold;
  padding: 0 4px;
  outline: none;
}

.gwt-StackLayoutPanel {
  border-bottom: 1px solid #bbbbbb;
}

.gwt-StackLayoutPanel .gwt-StackLayoutPanelHeader {
  cursor: pointer;
  cursor: hand;
  font-weight: bold;
  font-size: 1.3em;
  padding: 3px;
  border: 1px solid #bbbbbb;
  border-bottom: 0px;
  background: #d3def6 url(#{$images}/hborder.png) repeat-x 0px -989px;
}

.gwt-StackLayoutPanel .gwt-StackLayoutPanelHeader-hovering {
  background: #d3def6;
}

.gwt-StackLayoutPanel .gwt-StackLayoutPanelContent {
  border: 1px solid #bbbbbb;
  border-bottom: 0;
  background: white;
  padding: 2px 2px 10px 5px;
}

.gwt-TabLayoutPanel .gwt-TabLayoutPanelTabs {
  background: #ccc;
  padding-top: 6px;
  padding-left: 5px;
}

.gwt-TabLayoutPanel .gwt-TabLayoutPanelContentContainer {
  border-color: #ccc;
  border-style: solid;
  border-width: 0 1px 1px;
}

.gwt-TabLayoutPanel .gwt-TabLayoutPanelContent {
  overflow: hidden;
  padding: 6px;
}

.gwt-TabLayoutPanel .gwt-TabLayoutPanelTab {
  margin-left: 4px;
  padding: 4px 8px 4px 8px;
  cursor: pointer;
  cursor: hand;
  color: white;
  font-weight: normal;
  text-align: center;
  background: #8E8E8E;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.gwt-TabLayoutPanel .gwt-TabLayoutPanelTab-selected {
  cursor: default;
  background: white;
  color: #333;
  font-weight: bold;
}

.gwt-SplitLayoutPanel-HDragger {
  background: #e7e7e7 url(#{$images}/thumb_vertical.png) center center no-repeat;
  cursor: col-resize;
}

.gwt-SplitLayoutPanel-VDragger {
  background: #e7e7e7 url(#{$images}/thumb_horz.png) center center no-repeat;
  cursor: row-resize;
}
