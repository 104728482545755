// Steps - Solutions Table
// =======================

$icon-space: 19px;

.watch-list {
  clear: both;
  padding-top: size('half-spacer');
  width: 100%;

  // hide from users, but keep in Java templates
  .step-number {
    display: none;
  }
}


.step-row {
  border-color: color('border');
  border-style: solid;
  border-width: 1px 1px 0;
  position: relative;

  @include above('page-medium') {
    padding: size('half-shim');
  }

  &.highlighted-step {
    background-color: color('attention');
    outline: 1px solid color('border-focus');
    outline-offset: -1px;
  }

  &:nth-of-type(odd) {
    background-color: color('striping');

    &.highlighted-step {
      background-color: color('attention');
    }
  }

  &:first-of-type {
    @include contrasted('shadow-button');
    padding-left: calc(#{size('shim')} + 18px);

    @include below('page-medium') {
      .first-not-editable & {
        .step-equation-first,
        .step-equation-middle,
        .step-equation-last,
        .step-remove {
          display: none;
        }
      }
    }

    input {
      border-color: color('border');
    }
  }

  &:last-of-type {
    border-bottom: 1px solid color('border');

    input {
      border-color: color('border');
    }
  }

  input {
    border-color: transparent;
  }
}

.step-row-inner {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  overflow-y: hidden;

  @include above('page-medium') {
    flex-wrap: nowrap;
  }
}

.row-is-active {
  input {
    border-color: color('border');
  }
}

.toggle-input {
  flex: 2 1 20%;
  padding: size('half-shim');

  @include below('page-medium') {
    max-width: 80%;
    opacity: 0;
    pointer-events: none;
    position: absolute;

    .row-is-active &,
    .step-row:last-of-type & { //sass-lint:disable-line force-pseudo-nesting
      margin-bottom: size('shim');
      margin-top: size('shim');
      opacity: 1;
      pointer-events: initial;
      position: relative;
      transition: opacity 150ms;
    }
  }
}

.step-remove {
  flex: 0 0 auto;
  margin-right: size('half-shim');
  order: -1;

  @include below('page-medium') {
    margin-left: size('half-shim');
  }
}

.step-equation-first {
  font-family: STIXGeneral-Regular;
  text-align: right;
}

.step-equation-middle {
  flex: 0 0 auto;
  padding: 0;
  text-align: center;
}

.step-equation-last {
  font-family: STIXGeneral-Regular;
}

.step-typeset-equation {
  align-items: center;
  display: flex;
  flex: 5 0 85%;
  font-size: size('small');
  justify-content: center;
  min-height: size('step-height');
  padding: size('half-shim');

  @include below('page-medium') {
    .row-is-active &,
    .step-row:last-of-type:not(:first-of-type) & { //sass-lint:disable-line force-pseudo-nesting
      border-top: 1px dashed color('border');
    }
  }

  @include above('page-medium') {
    flex: 1 0 40%;
    text-align: right;
  }
}

.step-add {
  @include z-index('bump');
  position: absolute;
  right: size('half-shim');
  top: 100%;
  transform: translateY(-50%);

  @include above('page-medium') {
    right: size('shim');
  }
}


// Adding a step
// -------------

.add-step {
  display: flex;
  float: right;
  width: auto;
}


// Done solving
// ------------
.done-solving {
  clear: both;
  display: block;
  text-align: center;
}

