// About Specific Elements
// =======================



.about-project {
  margin-bottom: size('shim');

  @include above('max-text') {
    margin-right: size('spacer');
  }
}

.about-us {
  margin-bottom: size('shim');
}

.bio {
  display: block;
  margin-bottom: size('gutter');
  overflow: hidden;

  img {
    // @todo is there a better way to align images and wrapping text blocks
    margin-top: size('half-shim');
  }
}
