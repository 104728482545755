// Dialog Box
// ==========


.dialogContent {
  padding: size('shim');

  .gwt-HTML {
    margin-bottom: size('shim');
  }

  .gwt-Image {
    float: left;
    max-height: size('badge');
    max-width: size('badge');
    padding-bottom: size('shim');
    padding-right: size('shim');
    width: 100%;
  }

  .badge {
    float: left;
  }
}

.Caption {
  font-size: size('medium');
  font-weight: 700;
  padding-left: size('shim');
  padding-top: size('shim');
}

// yucky
.gwt-DialogBox {
  @include z-index('overlay');
  background-color: color('background');
  border: 1px solid color('border');
  box-shadow: -2px 2px 4px color('shadow-box');
  width: size('single-column');
}

.overlay-form-panel {
  @include z-index('overlay');
  background-color: color('background');
  border: 1px solid color('border');
  box-shadow: -2px 2px 4px color('shadow-box');
  max-width: size('single-column');
  padding: size('gutter');
  position: absolute;
}
