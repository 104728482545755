// Root Layout
// ===========

:root {
  background: color('background');
  color: color('text');
  font-size: calc(100% + 0.1vw);
}

/// @todo will we use this again?
/// or can it be merged into layout as is
@mixin cover {
  position: absolute;
  top: 0;
  width: 100%;
}

.layout {
  @include cover;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100%;

  > .gwt-TabLayoutPanel {
    height: 100%;
  }
}

// testing removal of inline styles added
// addPanel.addStyleName("addPanel");
.addPanel {
  width: 100%;
}

// utility to hide things until they can be removed in java
.hide-for-now {
  display: none;
}
