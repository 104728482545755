// Messages
// ========

.errorMessage {
  color: color('error');
}

// Error messages
.error-message {
  color: color('error');
  cursor: default;
  margin-bottom: size('shim');
}

.warning-message {
  background-color: color('warning');
  margin-bottom: size('shim');
  padding: size('shim');
}

@keyframes fade-out {
  0% {
    line-height: 0;
    opacity: 0;
  }

  5% {
    display: block;
    line-height: 1.3;
    margin-bottom: size('shim');
    opacity: 1;
    padding: size('shim');
  }

  75% {
    display: block;
    line-height: 1.3;
    margin-bottom: size('shim');
    opacity: 1;
    padding: size('shim');
  }

  100% {
    display: none;
    line-height: 0;
    opacity: 0;
  }
}

.form-message-general {
  animation: fade-out 5s ease-in-out forwards;
  background-color: color('warning');
  text-align: center;
  width: 100%;
}

.login-messages {
  p {
    margin-bottom: 0;
  }

  p + p {
    margin-top: 0;
  }
}
