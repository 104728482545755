// Panels
// ======

.text-panel {
  margin-bottom: size('shim');

  iframe {
    margin-bottom: size('gutter');
  }
}

// maximum widths for text columns
.contactUsLabel,
.topTextLabel,
.create-account {
  margin-bottom: size('shim');
  margin-left: auto;
  margin-right: auto;
  max-width: size('max-text');
}

.iframe-height-large {
  iframe {
    min-height: 60vh;
  }
}
