// Splash page specific elements
// =============================


// General splash patterns
// -----------------------

@mixin splash-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 70rem;
  width: 90%;
}

.splash-page {
  .btn-primary,
  .btn-secondary {
    display: inline-block;
    text-decoration: none;
  }
}

.splash-content {
  > div,
  .splash-quote {
    margin-bottom: size('half-spacer');
  }
}

.splash-text {
  @include text-block;
}

// Hero
// ----

.splash-hero {
  @include down-arrow('button-hover');
  background: url('../images/group-work.jpg') no-repeat 50% 50%;
  background-size: cover;
  padding: size('spacer') size('gutter');

  @include above('page-small') {
    padding-bottom: (size('spacer') * 2);
    padding-top: (size('spacer') * 2);
  }
}


// Solve instructions
// ------------------

.splash-instructions {
  padding: size('gutter');

  @include above('page-small') {
    padding-bottom: size('spacer');
    padding-top: size('spacer');
  }

  .extend-full {
    @include below('page-medium') {
      margin-left: negative('gutter');
      margin-right: negative('gutter');
      min-width: 98vw;
    }
  }

}



// How to use
// ----------

.splash-how {
  background: url('../images/chalkboard.jpg') no-repeat 50% 50%;
  background-size: cover;
  padding: size('gutter');

  @include above('page-small') {
    padding: size('spacer');
  }

  .splash-text {
    @include above('page-small') {
      padding-bottom: size('spacer');
      padding-top: size('spacer');
    }
  }
}


// About Emmy
// ----------

.splash-emmy {
  @include splash-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: size('gutter') 0;

  @include above('page-small') {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.splash-box {
  border: 1px solid color('border');
  margin-bottom: size('shim');
  margin-top: size('shim');

  @include above('page-small') {
    margin-bottom: size('gutter');
    margin-top: size('gutter');
    width: calc(50% - #{size('gutter')});
  }

  &:nth-of-type(3) {
    @include below('page-small') {
      order: 4;
    }
  }
}

.splash-box-text {
  padding: size('gutter');
}


// About the scientists
// --------------------

.splash-brand {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: size('gutter');

  @include above('page-small') {
    flex-direction: row;
    height: size('section-tall-half');
    justify-content: space-evenly;
  }

  .splash-logo-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: size('shim');
    padding: size('gutter');
    width: size('ext-logos');
  }
}

.splash-brand-container {
  background: url('../images/circuits.jpg') no-repeat 50% 50%;
  background-size: cover;

  @include above('page-small') {
    height: size('section-tall');
  }
}

.splash-about-what {
  @include splash-content;
  @include z-index('bump');
  background-color: color('background');
  border: 1px solid color('border');
  padding: size('half-spacer');

  @include above('page-small') {
    margin-top: negative('section-tall-half');
  }
}

.about-who {
  display: flex;
  flex-direction: column;
  margin-top: size('half-spacer');

  @include above('page-small') {
    flex-direction: row;
    justify-content: space-between;
  }
}

.splash-bio {
  // roughly a third with gutters from justified parent
  flex: 0 1 29%;

  @include below('page-small') {
    margin-bottom: size('gutter');
  }
}

.splash-bio-headshot {
  // restricts total size
  height: 40%;
  margin-bottom: size('gutter');
  max-width: size('headshot');
  object-fit: cover;
  width: 100%;
}


// cta {
.splash-cta {
  padding-bottom: size('gutter');
}


// quote
.splash-quote {
  display: block;
  margin: size('gutter');
  padding: size('gutter');

  @include above('page-small') {
    padding-left: size('spacer');
    padding-right: size('spacer');
  }

  p,
  footer {
    padding-left: size('spacer');
  }

  p {
    font-size: size('h3');

    @include above('page-small') {
      font-size: size('h2');
    }
  }

  footer {
    border: 0;
    font-size: size('medium');
  }
}
