// Points
// ======


.callouts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include below('page-medium') {
    min-width: 100%;
  }

  @include above('page-medium') {
    float: left;
    width: 50%;
  }
}

.callout {
  @include fancy-border;
  border: 1px solid color('border');
  color: color('text');
  display: flex;
  flex: 0 0 30%;
  flex-direction: column;
  margin-bottom: size('shim');
  padding: size('half-shim') size('shim');
  text-align: center;

  @include above('page-medium') {
    padding-left: size('gutter');
    padding-right: size('gutter');

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.callout-title {
  @include font-family('sans');
  font-size: size('medium');
  font-weight: 700;
}

.callout-context {
  font-size: size('smaller');
}

.callout-value {
  color: color('points');
  font-size: size('h2');
  font-weight: 700;
  line-height: 1;
}

.awaiting-feedback {
  ~ div {
    display: none;
  }

  &[aria-hidden='true'] {
    ~ div {
      display: block;
    }
  }
}
