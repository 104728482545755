// Interface Help Tutorial
// =======================

.intro-group {
  width: 100%;

  @include above('page-medium') {
    float: left;
    margin-right: 5%;
    width: 45%;
  }
}

.help-group {
  border-top: 1px solid color('border');
  margin-top: size('gutter');
}

.help-links-nav {
  @include clearfix;
  margin-bottom: size('shim');
}

.tutorial-link {
  float: right;
  font-size: size('small');
  margin: 0;
  padding: size('half-shim');
}

.expand-arrow {
  &.is-expanded {
    [data-icon] {
      transform: rotate(180deg);
    }
  }

  .expand-circle-arrow {
    fill: color('background');
  }

  .expand-circle-fg {
    fill: color('link');
  }

  .expand-circle-bg {
    fill: color('link');
  }

  #{$focus} {
    .expand-circle-arrow {
      fill: color('link');
    }

    .expand-circle-fg {
      fill: color('background');
    }

    .expand-circle-bg {
      fill: color('link');
    }
  }
}

.expand-arrow,
.interface-help-toggle {
  display: block;
  left: -1px; // to align with line due to padding
  padding: 1px; // to prevent cropping from subpixel rounding
  position: relative;
  top: negative('half-shim');
  transition: fill 0.3s;

  [data-icon] {
    transition: transform 0.3s;
  }
}

.interface-help-description {
  border-bottom: 1px solid color('border');
  margin-bottom: size('shim');
  padding: size('gutter') 0;
}

