// Font Settings
// =============

@import 'accoutrement-type/sass/type';


// Font Formats
// ------------
/// The font-formats that we want to import
/// @group fonts
/// @link http://oddbird.net/accoutrement-type/sassdoc/
///   Accoutrement Type
$font-formats: 'otf' 'ttf';


// Body Font
// ---------
/// The brand font,
/// used for most body text.
/// @preview font-specimen; key: body
/// @group fonts
/// @link http://oddbird.net/accoutrement-type/sassdoc/
///   Accoutrement Type
$body-font: (
  'name': 'Alegreya',
  'stack': ('Baskerville', 'Palatino', 'Cambria', 'Georgia', serif),
  'regular': 'serif/Alegreya-Regular',
  'italic': 'serif/Alegreya-Italic',
  'bold': 'serif/Alegreya-Bold',
  'bold-italic': 'serif/Alegreya-BoldItalic',
);

@include herman-add-font('body', $body-font);


// Sans Font
// ---------
/// A clean sans-serif option
/// used for headers and smaller text like captions and labels.
/// @preview font-specimen; key: sans
/// @group fonts
/// @link http://oddbird.net/accoutrement-type/sassdoc/
///   Accoutrement Type
$sans-font: (
  'name': 'AlegreyaSans',
  'stack': ('Helvetica Neue', 'Helvetica', 'Arial', sans-serif),
  'regular': 'sans/AlegreyaSans-Regular',
  'italic': 'sans/AlegreyaSans-Italic',
  'bold': 'sans/AlegreyaSans-Bold',
  'bold-italic': 'sans/AlegreyaSans-BoldItalic',
);

@include herman-add-font('sans', $sans-font);


// Math Font
// ---------
/// A readable font for displaying math equations.
/// @preview font-specimen; key: math
/// @group fonts
/// @link http://oddbird.net/accoutrement-type/sassdoc/
///   Accoutrement Type


// Code Font
// ---------
/// A readable monospace font for code previews.
/// @preview font-specimen; key: code
/// @group fonts
/// @link http://oddbird.net/accoutrement-type/sassdoc/
///   Accoutrement Type
$code-font: (
  'name': 'SourceCodePro',
  'stack': ('Consolas', 'Menlo', 'Monaco', 'Lucida Console', 'Liberation Mono', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Courier New', monospace, sans-serif),
  'regular': 'code/sourcecodepro-regular-webfont',
  'bold': 'code/sourcecodepro-bold-webfont',
);

@include herman-add-font('code', $code-font);


// Import Font Files
// -----------------
// Import individually since file format differs
@include font-face('body', 'otf');
@include font-face('sans', 'otf');
@include font-face('code', 'ttf');
