// Icons
// =====

/// A data attribute for setting an icon.
/// @group icons
/// @example html
///    <svg data-icon="plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19"><path d="M15.5,9H10V3.5C10,3.2,9.8,3,9.5,3S9,3.2,9,3.5V9H3.5C3.2,9,3,9.2,3,9.5S3.2,10,3.5,10H9v5.5C9,15.8,9.2,16,9.5,16 s0.5-0.2,0.5-0.5V10h5.5c0.3,0,0.5-0.2,0.5-0.5S15.8,9,15.5,9z"/></svg>
[data-icon] {
  box-sizing: content-box;
  fill: currentColor;
  height: 18px; // avoid subpixel rounding
  width: 18px;
}
