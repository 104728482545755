// sass-lint:disable-all

// base GWT styles
@import 'clean';


/** Add css rules here for your application. */
.gwt-TabLayoutPanelContent {
	width: 75%;
	margin: auto;
	border: none;
	border-style: none;
}

.award {
	text-align: center;
	align: center;
}

.gwt-Button-consentSubmitButton {
	margin: 25px 0 15px 0;
	align: center;
}

.algebraSolutionTable {
	margin: 10px 0 15px 0;
	align: center;
}

.feedbackPanelText {
	margin-bottom: 30px;
}

.skillMasteryText {
	margin-bottom: 30px;
}

.progressBar {
	margin-top: 10px;
	margin-bottom: 10px;
	color: purple;
	font-size: 1vw;
	font-weight: 100;
	font-family: 'Courier New', monospace;
}

.contactUsLabel {
	width: 99%;
	margin: 8px 8px 8px 8px;
}

.aboutUsLabel{
	width: 98%;
	margin: -5px 8px 8px 8px;
}

.lineInCreateUserPanel {
	width: 100%;
	margin: 10px 0 10px 0;
}

.labelInCreateUserPanel {
	font-weight: bold;
}

.gwt-TabLayoutPanel .gwt-TabLayoutPanelTabs.gwt-TabLayoutPanelTabs {
	margin: 0 0 0 0
}

.gwt-TabLayoutPanel .gwt-TabLayoutPanelTab.gwt-TabLayoutPanelTab {
	color: #FFFFFF;
}

.gwt-TabLayoutPanel .gwt-TabLayoutPanelTab.gwt-TabLayoutPanelTab-selected {
	background-color: white;
	color: #2062B8;
}

.turkCode {
	font-size: 1em;
	font-weight: bold;
	font-family: "Times New Roman",Times,serif
}

/** Example rules used by the template application (remove for your app) */


.loggedInUserDisplayPanel {
	color: #2062B8;
	font-size: 1em;
	font-weight: bold;
	height: 20px;
	line-height: 20px;
	margin: 0 0 0 0;
	text-align: right;
}

.loggedInUserText {
	font-size: 1em;
	font-weight: bold;
	color: white;
/*   height: 100px;
	line-height: 100px; */
	 margin: 0 0 0 0;

	 text-align: right;
 }

.changeLoginLabel {
	font-size: 1em;
	font-weight: bold;
	color: white;
	 margin: 0 10px 0 5px;
}

.sendButton {
	display: block;
	font-size: 16pt;
}

#blue .ui-slider-range { background: #729fcf; }
#blue .ui-slider-handle { border-color: #729fcf; }
