// Alignment
// =========


.align-left {
  float: left;
  margin-bottom: size('shim');
  margin-right: size('shim');
}

.align-right {
  float: right;
  margin-bottom: size('shim');
  margin-left: size('shim');
}

.align-center {
  margin-bottom: size('shim');
  text-align: center;
}
