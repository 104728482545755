// Color Config
// ============

// Brand Colors
// ------------
/// The brand is defined by these base colors
/// @preview color-palette
/// @group colors
/// @type Map
$brand-colors: (
  'purple': hsl(249, 30%, 51%),
  'orange': hsl(18, 92%, 63%),
  'green': hsl(99, 56%, 42%),
);

@include herman-add-colors('brand-colors', $brand-colors);


// State and Action Colors
// -----------------------
/// Colors for UI elements, often convey state or action
/// @preview color-palette
/// @group colors
/// @type Map
$state-colors: (
  'success': hsl(155, 89%, 28%),
  'warning': hsl(66, 89%, 62%),
  'error': hsl(345, 89%, 48%),
);

@include herman-add-colors('state-colors', $state-colors);


// Component Colors
// ----------------
/// Component based colors
/// @preview color-palette
/// @group colors
/// @type Map
$component-colors: (
  'background': #fff,
  'background-bright': 'green',
  'dark-theme': 'purple',
  'text': 'orange' (desaturate: 77%, darken: 27%),
  'text-light': 'orange' (desaturate: 78%, darken: 17%),
  'callout':  'orange' (desaturate: 85%, lighten: 35%),
  'banner': 'purple',
  'border': 'orange' (desaturate: 85%, lighten: 20%),
  'border-focus': 'orange',
  'fancy-border': 'orange',
  'link':  'green',
  'link-hover':  'green' (darken: 8%),
  'button': 'purple',
  'button-hover':  'purple' (darken: 10%),
  'overlay-light': hsla(0, 0%, 100%, 0.9),
  'points': 'orange',
  'shadow-box': hsla(18, 89%, 6%, 0.2),
  'shadow-button': 'purple' (darken: 19%),
  'attention': 'orange' (rgba: 0.1),
  'striping': 'green' (rgba: 0.1),
);

@include herman-add-colors('component-colors', $component-colors);
