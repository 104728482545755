// Profile
// =======

// Badge inner styles
// Badge layout styles are in profile since many are shared

.badge-total {
  color: color('text-light');
  font-weight: 400;
}

.badge {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: size('gutter');
  padding: size('shim');
  position: relative;
  text-align: center;
  width: size('badge');
}


.badge-icon {
  max-width: size('badge-placeholder');

  .nonfeedback-badge & {
    max-width: size('badge');
  }
}

.badge-name {
  margin-top: size('shim');
}


.badge-number {
  color: color('background');
  display: none;
  font-size: 3rem;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);

  // peacock
  .max-correct & {
    left: 67%;
    top: 48%;

    .dialogContent & {
      left: 62%;
      top: 57%
    }
  }

  // cat
  .max-problems & {
    left: 42%;
    top: 30%;

    .dialogContent & {
      left: 40%;
      top: 35%;
    }
  }

  // goat
  .feedback-mastery & {
    left: 34%;
    top: 30%;

    .dialogContent & {
      left: 32%;
      top: 30%;
    }
  }
}

.nonfeedback-badge {
  &.is-earned {
    .badge-number {
      display: block;
    }
  }
}

.badges-divider {
  border-top: 1px solid color('border');
  display: block;
  margin-bottom: size('gutter');
  width: 100%;
}
