// Layout Helpers
// ==============


/// Text Block Base Styles
/// @group utilities
/// @example scss
///    .foo {
///      @include text-block;
///    }
@mixin text-block {
  margin-left: auto;
  margin-right: auto;
  max-width: size('max-text');
  width: 100%;
}
