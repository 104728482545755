// Footer
// ======


footer {
  border-top: 1px solid color('border');

  a {
    #{$link} {
      color: color('text-light');
    }

    #{$focus} {
      color: color('link-hover');
    }
  }
}

.footer-inner {
  align-items: center;
  color: color('text-light');
  display: flex;
  font-size: size('small');
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: size('page-large');
  padding: 0 size('half-spacer');
}

.site-info {
  display: flex;
  flex-shrink: 1;
  padding: size('link-pad');
}
