// Type
// ====

// Import Font Files
// -----------------
// Import individually since file format differs
@include font-face('body', 'otf');
@include font-face('sans', 'otf');
@include font-face('code', 'ttf');


:root {
  @include font-family('body');
}

a {
  text-decoration: underline;

  #{$link} {
    color: color('link');
  }

  #{$focus} {
    color: color('link-hover');
  }
}

p {
  margin-bottom: size('shim');

  .static-page & {
    line-height: 1.5;
  }
}

.page-title {
  font-size: size('h1');
  line-height: 1.2;
  margin-bottom: size('shim');
  text-align: center;
}

.section-title {
  font-size: size('h2');
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: size('half-shim');

  p + & {
    margin-top: size('gutter');
  }
}

.section-subtitle {
  @include font-family('sans');
  font-size: size('h3');
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: size('half-shim');

  // Specify page template to make sure bottom margin
  // used in future static content isn't too big
  .profile-page & {
    margin-bottom: size('gutter');
  }
}

.headline-subtitle  {
  @include font-family('sans');
  font-size: size('h3');
  font-weight: 300;
  line-height: 1.2;
}

.fancy-title {
  @include after('') {
    background-color: color('fancy-border');
    display: block;
    height: 5px;
    margin: size('shim') 0;
    width: 5rem;
  }
}

ul {
  &.profile-list {
    margin-left: size('half-shim');
  }

  &.simple-list {
    li {
      align-items: center;
      display: flex;
      list-style-type: none;

      @include before('•') {
        font-size: size('smallest');
        padding-right: size('half-shim');
      }
    }
  }
}

ol {
  margin: 0 0 size('half-shim') size('list-indent');
}

blockquote {
  @include before(open-quote) {
    font-size: 6rem;
    line-height: 1;
    position: absolute;
  }
}
