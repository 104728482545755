// Images
// ======


img {
  height: auto;
}

.img-large {
  max-width: size('page');
  width: 100%;
}

.img-medium {
  max-width: size('page-small');
  width: 100%;
}

.img-small {
  max-width: size('badge');
  width: 100%;
}

.img-cover {
  height: 100%;
  max-width: initial;
  object-fit: cover;
  width: 100%;
}
