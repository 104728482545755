// Header
// ======


// layout of non-nav elements
// --------------------------

header {
  @include shadow;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;

  @include below('nav-update') {
    justify-content: space-between;
  }

  a {
    text-decoration: none;

    #{$link} {
      color: color('background');
    }

    &:not(.site-title) {
      #{$focus} {
        background-color: color('link');
      }
    }
  }

  button {
    color: color('background');

    #{$focus} {
      background-color: color('link');
    }
  }
}

.site-title {
  max-height: 45px; // restrict logo height so menu items are flush
  top: 0;

  @include below('nav-update') {
    max-width: 28vw;

    [data-icon='icon-logo'] {
      display: none;
    }
  }

  @include above('nav-update') {
    left: 50%;
    max-width: calc(12rem + 2.25vw);
    position: absolute;
    transform: translateX(-50%);

    [data-icon='icon-logo-stacked'] {
      display: none;
    }
  }

  [data-icon] {
    color: color('background');
    height: size('touch');
    padding: size('half-shim') 0;
    width: 100%;
  }
}
