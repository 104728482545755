// Solid Shadows
// =============

/// @group utilities
/// @example scss
///    .btn-primary {
///      @include shadow('button', 'shadow-button', 'button-hover');
///    }
/// @example css
///   .btn-primary {
///        background-color: #685da8;
///        box-shadow: 0 6px #40396a;
///    }
///
///    .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
///        background-color: #534988
///    }
///
///    .btn-primary:hover {
///        box-shadow: 0 4px #40396a;
///        top: 2px
///    }
///
///    .btn-primary:active {
///        box-shadow: 0 0 #40396a;
///        top: 6px
///    }
/// @example html
///   <button type="button" class="btn-primary">Shadow</button>

@mixin shadow(
  $bgcolor: 'button',
  $shadowcolor: 'shadow-button',
  $bghover: null
) {
  background-color: color($bgcolor);
  box-shadow: 0 6px color($shadowcolor);

  // if bg is not null, then add focus/hover/active states
  @if $bghover {
    #{$focus} {
      background-color: color($bghover);
    }

    &:hover {
      box-shadow: 0 4px color($shadowcolor);
      top: 2px;
    }

    &:active {
      box-shadow: 0 0 color($shadowcolor);
      top: 6px;
    }
  }
}
