// Overrides
// =========

.logged-out-message {
  background-color: color('warning');
  margin-bottom: size('shim');
  padding: size('half-shim') size('shim');
}

// Override blue backgrounds
.loggedInUserDisplayPanel,
.loggedInUserText,
.changeLoginLabel {
  background: color('text');
}

.ui-slider-range {
  &,
  &.ui-widget-header {
    background: color('text');
  }
}

.gwt-TabLayoutPanel {
  .gwt-TabLayoutPanelTabs {
    &.gwt-TabLayoutPanelTabs {
      background: color('text');
    }
  }

  .gwt-TabLayoutPanelTab {
    &.gwt-TabLayoutPanelTab {
      background: color('text');
    }
  }
}

.titleHeader {
  background: color('text');
  color: color('background');
  font-style: italic;
  text-align: center;
}

.serverResponseLabelError {
  color: color('error');
}
