// Borders
// =======

// Emmy's Workshop Border Pattern
// ------------------------------
/// Apply the brand one-rounded-corner look to an element
/// `corners` in $component-sizes map located in _scale.scss
/// @group borders
/// @require SassCore::map-has-key <http://oddbird.net/accoutrement-scale/sassdoc/sizes.html#function--size>
/// @example scss - Usage (scss)
///   .foo {
///     @include fancy-border;
///   }
/// @example css - Output (css)
///   .foo {
///     border-radius: 0 0 8px 0;
///   }
/// @example html
/// <input class="gwt-TextBox">
@mixin fancy-border {
  border-radius: 0 0 size('corner');
}


/// @group utilities
/// @param $bordercolor [ background ]
/// @example scss
///    .splash-text {
///      @include inset-border('background');
///    }
@mixin inset-border(
  $bordercolor: 'background'
) {
  border: 1px solid color($bordercolor);
  padding: size('gutter');

  @include above('page-small') {
    padding: size('half-spacer');
  }
}

.inset-container {
  @include inset-border('background');
}

