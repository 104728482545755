// Scale Settings
// ==============

// Text Ratios
// -----------
/// A set of standard ratios
/// for creating text rhythm and modular-scales.
///
/// @group scale
/// @preview ratios
/// @link http://oddbird.net/accoutrement-scale/sassdoc/
///   Accoutrement Scale
$text-ratios: (
  'line-height': 1.4,
);

@include herman-add-ratios('text-ratios', $text-ratios);


// Text Sizes
// ----------
/// A set of standard text sizes.
///
/// @group scale
/// @preview sizes
/// @link http://oddbird.net/accoutrement-scale/sassdoc/
///   Accoutrement Scale
$text-sizes: (
  'root': 22px,

  'h1': 'root' ('line-height': 2.5),
  'h2': 'root' ('line-height': 1.75),
  'h3': 'root' ('line-height': 1),

  'large': 26px,
  'medium': 24px,
  'small': 18px,
  'smaller': 16px,
  'smallest': 14px,
);

@include herman-add-sizes('text-sizes', $text-sizes);


// Spacing Sizes
// -------------
/// A set of standard spacing sizes.
///
/// @group scale
/// @preview sizes; style: ruler
/// @link http://oddbird.net/accoutrement-scale/sassdoc/
///   Accoutrement Scale
$spacing-sizes: (
  'rhythm': 'root' ('line-height': 1),
  'gutter': 'rhythm',
  'spacer': 'gutter' ('linear': 3),
  'half-spacer': 'spacer' ('linear': 0.5),
  'shim': 'gutter' ('linear': 0.5),
  'half-shim': 'gutter' ('linear': 0.25),
  'touch': 44px,
);

@include herman-add-sizes('spacing-sizes', $spacing-sizes);


// Pattern Sizes
// -------------
/// A set of pattern-specific sizes,
/// that can be applied to various components.
///
/// @group scale
/// @preview sizes; style: output-only
/// @link http://oddbird.net/accoutrement-scale/sassdoc/
///   Accoutrement Scale
$pattern-sizes: (
  'page': 50rem,
  'page-large': 70rem,
  'page-medium': 41rem,
  'page-small': 32rem,
  'specimen': 'rhythm' ('linear': 3),
  'color-swatch': 'rhythm' ('linear': 4),
  'nav-update': 32rem,
  'single-column': 32rem,
  'max-text': 44rem,
  'step-height': 'rhythm' ('linear': 2.5),
  'link-pad': 'shim',
  'button': 6rem,
  'corner': 8px,
  'badge': 11rem,
  'badge-placeholder': 9rem,
  'list-indent': 1rem,
  'icon-padding': 5px,
  'ext-logos': 17rem,
  'headshot': 20rem,
  'section-tall': 50vh,
  'section-tall-half': 'section-tall' ('linear': 0.5),
);

@include herman-add-sizes('pattern-sizes', $pattern-sizes);
