// Buttons
// =======


// Algebra Table - Showing steps
// -----------------------------
/// @group Buttons
/// @example html
///   <span class="step-remove"><button type="button" class="gwt-Button gwt-Button-remove remove-step-icon step-icon"><svg class="remove" data-icon="icon-plus"><use xlink:href="#icon-plus"><svg id="icon-plus" viewBox="0 0 19 19" width="100%" height="100%"><path d="M15.5 9H10V3.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5V9H3.5c-.3 0-.5.2-.5.5s.2.5.5.5H9v5.5c0 .3.2.5.5.5s.5-.2.5-.5V10h5.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"></path></svg></use></svg><span class="screen-reader-text">Remove step</span></button></span>
///   <span class="step-add"><button type="button" class="gwt-Button gwt-Button-add add-step-icon step-icon"><svg class="add" data-icon="icon-plus"><use xlink:href="#icon-plus"><svg id="icon-plus" viewBox="0 0 19 19" width="100%" height="100%"><path d="M15.5 9H10V3.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5V9H3.5c-.3 0-.5.2-.5.5s.2.5.5.5H9v5.5c0 .3.2.5.5.5s.5-.2.5-.5V10h5.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"></path></svg></use></svg><span class="screen-reader-text">Add step</span></button></span>

.step-icon {
  align-items: center;
  display: flex;
  justify-content: center;

  [data-icon] {
    background-color: color('background');
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;

    #{$focus} {
      color: color('background');
    }
  }
}

[data-icon='icon-plus'] {
  &.remove {
    color: color('error');
    transform: rotate(45deg);

    #{$focus} {
      background-color: color('error');
      border-color: color('error');
    }
  }

  &.add {
    color: color('link');

    #{$focus} {
      background-color: color('link');
      border-color: color('link');
    }
  }

  .add-step & {
    &.add {
      #{$focus} {
        background-color: color('background');
        border-color: color('transparent');
        color: color('link');
      }
    }
  }
}


// Base primary and secondary styles
// ---------------------------------
/// @group Buttons
/// @example html
///   <button type="button">Base</button>
@mixin btn-base {
  @include font-family('sans');
  border-radius: 0;
  font-size: size('medium');
  letter-spacing: 0.01rem;
  margin-bottom: size('shim');
  margin-top: size('shim');
  padding: size('half-shim') size('shim');
  position: relative;

  @include above('page') {
    padding-left: size('gutter');
    padding-right: size('gutter');
  }
}


// Primary Button Style
// --------------------
/// @group Buttons
/// @example html
///   <button type="button" class="btn-primary">Primary</button>
.btn-primary {
  &,
  #{$link} {
    @include btn-base;
    @include shadow('button', 'shadow-button', 'button-hover');
    appearance: none;
    border: 0;
    color: color('background');
    transition: none;
  }

  @include after('') {
    position: absolute;
    transition: all 0.3s;
    z-index: -1;
  }

  .add-step & {
    @include shadow('link', 'link-hover', 'link-hover');
    font-size: size('small');
  }

  &[disabled] {
    color: color('overlay-light');

    #{$focus} {
      border: 0;
    }
  }
}

// Outlined Buttons
// ----------------
/// @group Buttons
/// @example html
///   <button type="button" class="btn-secondary">Secondary</button>
.btn-secondary {
  &,
  #{$link} {
    @include btn-base;
    background-color: color('background');
    border: 1px solid color('button');
    color: color('button');

    #{$focus} {
      background-color: color('button');
      color: color('background');

      .dark-theme & {
        // only change border on dark theme
        // button would look smaller if done on default theme
        border-color: color('background');
      }
    }
  }

  &.btn-active {
    background-color: color('button');
    color: color('background');

    #{$focus} {
      background-color: color('button' ('shade': 20%));
    }
  }

  &.btn-inactive {
    border: 1px solid color('button' ('tint': 20%));
    color: color('button' ('tint': 20%));

    #{$focus} {
      color: color('background');
    }
  }
}

button + button {
  margin-left: size('gutter');
}

.btn-textlink {
  color: color('link');
  cursor: pointer;
  margin: size('gutter') auto;
  text-align: center;
  text-decoration: underline;

  #{$focus} {
    color: color('link-hover');
  }
}

.btn-cancel {
  color: color('error');

  #{$focus} {
    color: color('border-focus');
  }
}

.btn-label {
  margin-left: size('half-shim');
}

.btn-inline {
  display: inline-flex;
}
