// Tutorial Specific Elements
// ==========================


.tutorial-wrapper {
  text-align: center;

  img {
    margin-bottom: size('gutter');
    margin-top: size('shim');
  }
}

.tutorial-btns {
  border-top: 1px solid color('border');
  display: flex;
  justify-content: flex-end;
  margin-top: size('shim');
  padding-top: size('shim');
  width: 100%;

  button + button {
    margin-left: 0;
  }

  .btn-continue {
    margin-left: auto;
  }

  .watch-list + & {
    margin-top: size('spacer');
  }
}

.tutorial-add-space {
  padding-bottom: size('spacer');
  padding-top: size('spacer');
}
