// Arrows
// ======

@mixin down-arrow(
  $bgcolor: 'dark-theme',
  $arrowsize: 'gutter'
) {
  position: relative;

  @include after('') {
    border-color: transparent;
    border-style: solid;
    border-top-color: color($bgcolor);
    border-width: size($arrowsize);
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
  }
}
