// Profile
// =======

.profile-page {
  position: relative;

  @include above('page-small') {
    display: flex;
    flex-wrap: wrap;
  }
}


// Items in the top area of profile page
// -------------------------------------
.profile-head {
  align-items: center;
  display: flex;
  margin-bottom: size('gutter');

  @include below('page-small') {
    flex-wrap: wrap;
  }

  @include above('page-small') {
    flex: 1 1 auto;
    order: 0;
  }
}

.profile-bio {
  flex: 1 0 100%;

  @include above('page-small') {
    flex: 1 0 auto;
    margin-right: auto;
  }
}

.profile-email {
  font-weight: 700;

  @include below('page-small') {
    flex: 1 0 100%;
  }

  // add separator between email and joined date
  + .meta {
    margin-right: size('half-shim');

    @include above('page-small') {
      @include before(' | ') {
        padding-left: size('half-shim');
        padding-right: size('half-shim');
      }
    }
  }
}

.meta-group {
  display: flex;

  @include below('page-small') {
    flex-wrap: wrap;
  }
}


// Items in the body of profile page
// ---------------------------------
.profile-body {
  display: flex;
  flex-wrap: wrap;

  @include above('page-small') {
    display: block;
    order: 2;
  }
}

// Single grouped element like stats, notifications, etc
.profile-group {
  @include fancy-border;
  border: 1px solid color('border');
  margin-bottom: size('gutter');
  padding: size('gutter');

  @include above('page-small') {
    padding: size('half-spacer');
  }

  // Faux floating - apply styles to an inner div instead
  &.profile-notifications {
    @include above('page-small') {
      border-color: transparent;
      padding: 0;
    }
  }
}

// Faux floating - applying profile-group styles to this inner div instead
.profile-group-inner {
  @include above('page-small') {
    @include fancy-border;
    border: 1px solid color('border');
    margin: -1px;
    padding: size('half-spacer');
  }
}

// Layout of main profile panels
.profile-stats,
.profile-notifications {
  flex: 1 0 100%;

  @include above('page-small') {
    // flex: 0 0 35%;
    float: left;
    margin-right: size('gutter');
    width: 35%;
  }
}

.profile-badges,
.profile-feedback {
  flex: 1 0 100%;

  @include above('page-small') {
    //flex-basis: 60%;
    float: right;
    width: 60%;
  }
}


// Stat specific
.stat-row {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin-bottom: size('shim');
}

.profile-property {
  @include font-family('sans');
}

.profile-value {
  font-size: size('h3');
}

// Badge Specific Layout
.badge-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.profile-badges {
  @include above('page-small') {
    order: 5;
  }
}


// Profile Actions
// ---------------
.profile-actions {
  @include above('page-small') {
    order: 1;
  }

  .btn-secondary {
    &:not(:last-of-type) {
      margin-right: size('half-shim');
    }
  }

  .overlay-form-panel {
    margin-top: negative('shim');
  }

  .btn-cancel {
    margin-left: size('gutter');
  }
}
