// Main Region Layout
// ==================

.main-region {
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: size('page-large');
  padding: size('gutter');
  width: 100%;
}

.single-column {
  margin-left: auto;
  margin-right: auto;
  max-width: size('single-column');
  width: 100%;
}

.text-block {
  @include text-block;
  margin-bottom: size('gutter');
  margin-top: 0;
  text-align: left;

  .tutorial-wrapper & {
    margin-top: size('gutter');
  }
}

.multi-col-group {
  @include above('page-medium') {
    img {
      float: left;
      margin-right: size('spacer');
      max-width: 50%;
    }
  }
}

.multi-col-text {
  text-align: left;
}

.static-page {
  display: flex;
  flex-direction: column;

  &.about-page {
    @include above('max-text') {
      flex-direction: row;
    }
  }
}
