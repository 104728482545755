// Forms
// =====

form {
  button,
  label,
  input,
  .gwt-Button,
  .gwt-Label,
  .gwt-TextBox,
  .gwt-PasswordTextBox,
  .gwt-TextArea {
    @include font-family('sans');
  }

  tr {
    td {
      &:first-of-type {
        text-align: right;
      }
    }
  }

  // Text inputs and textarea in forms only
  .gwt-TextBox,
  .gwt-PasswordTextBox,
  .gwt-TextArea {
    margin-bottom: size('gutter');
  }

  td {
    .gwt-TextBox,
    .gwt-PasswordTextBox,
    .gwt-TextArea {
      margin-bottom: 0;
    }
  }
}


.radioButtonsInAgePanel {
  display: block;
  margin: size('half-shim') 0;
}

// Text inputs and text area in forms or tables
.gwt-TextBox,
.gwt-PasswordTextBox,
.gwt-TextArea {
  @include fancy-border;
  border: 1px solid color('border');
  padding: size('half-shim');
  width: 100%;

  &:focus {
    outline: 0;
  }

  #{$focus} {
    border-color: color('border-focus');
  }
}

.form-panel {
  margin: size('gutter') 0;
  width: 100%;

  table {
    width: 100%;
  }

  button {
    display: block;
    margin: size('half-shim') auto;
  }
}

.create-account {
  td {
    padding: size('half-shim');
  }

  button {
    display: block;
    margin: size('half-shim') auto;
  }
}

.reset-password {
  input {
    width: 100%;
  }
}


// Checkbox Input
// --------------

.gwt-CheckBox {
  display: block;
  margin-bottom: size('gutter');
}


// Checkboxes and Radios
input {
  &[type='checkbox'],
  &[type='radio'] {
    + label {
      margin-left: size('half-shim');
    }
  }
}
